<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col>
        <analytics-congratulation :data="userData" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card :title="'Останні замовлення'">
          <Orders :frame="true" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

import Orders from '@/views/orders/List'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Orders,
    AnalyticsCongratulation,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(useJwt.jwtConfig.userDataKey)),
    }
  },
}
</script>
