<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('administration.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('administration.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="payment_method_id">{{ $t('administration.table.fields.payment_method_id') }}</label>
          <v-select
            id="payment_method_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="paymentMethodFilter"
            :options="paymentMethodOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:paymentMethodFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="delivery_method_id">{{ $t('administration.table.fields.delivery_method_id') }}</label>
          <v-select
            id="payment_method_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="deliveryMethodFilter"
            :options="deliveryMethodOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:deliveryMethodFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    paymentMethodFilter: {
      type: [Number, null],
      default: null,
    },
    deliveryMethodFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      paymentMethodOptions: [],
      deliveryMethodOptions: [],
      statusOptions: [
        { label: this.$t('orders.statuses.new'), value: 'new' },
        { label: this.$t('orders.statuses.paid'), value: 'paid' },
        { label: this.$t('orders.statuses.inwork'), value: 'inwork' },
        { label: this.$t('orders.statuses.specification'), value: 'specification' },
        { label: this.$t('orders.statuses.noconnection'), value: 'noconnection' },
        { label: this.$t('orders.statuses.accepted'), value: 'accepted' },
        { label: this.$t('orders.statuses.delivering'), value: 'delivering' },
        { label: this.$t('orders.statuses.completed'), value: 'completed' },
        { label: this.$t('orders.statuses.cancel'), value: 'cancel' },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/administration/payment_methods')
      .then(response => {
        this.paymentMethodOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get('/api/administration/delivery_methods')
      .then(response => {
        this.deliveryMethodOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
